<template>
  <v-container v-if="itemCharacter" fluid class="form-component">
    <v-row class="mt-2">
      <v-col cols="3">
        <label for="">Width</label>
        <v-text-field type="number" v-model="itemCharacter.width" @input="changePosition" />
      </v-col>
      <v-col cols="3">
        <label for="">Height</label>

        <v-text-field type="number" v-model="itemCharacter.height" @input="changePosition" />
      </v-col>
      <v-col cols="3">
        <label for="">X</label>

        <v-text-field type="number" v-model="itemCharacter.x" @input="changePosition" />
      </v-col>
      <v-col cols="3">
        <label for="">Y</label>

        <v-text-field type="number" v-model="itemCharacter.y" @input="changePosition" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <!-- {{ itemCharacter }} -->
      <v-col cols="12">
        <label for="">Characters</label>
        <v-select
          v-model="itemCharacter.targetCharacter.code"
          @change="changeCharacter"
          item-value="code"
          item-text="title"
          :items="characters"
        />
      </v-col>
      <v-col cols="12">
        <label for="">Avatar</label>
        <v-select
          :value="`Avatar`"
          item-text="name"
          item-value="name"
          :items="[{ name: `Avatar` }, { name: `Name`, disabled: true }]"
          item-disabled="disabled"
        />
      </v-col>
    </v-row>
    <template
      v-if="itemCharacter.targetCharacter.code && itemCharacter.targetCharacter.code !== 'other' && isShowAvatarGrid"
    >
      <AvatarGrid
        @setDataImage="getsetDataImage"
        cols="4"
        v-for="(group, idx) in itemCharacter.characterResources"
        :key="idx"
        :index="idx"
        :group="group"
        :code="itemCharacter.targetCharacter.code"
      />
    </template>
    <template
      v-if="itemCharacter.targetCharacter.code && itemCharacter.targetCharacter.code === 'other' && isShowAvatarGrid"
    >
      <v-col cols="12">
        <h4 class="mb-3">Other image</h4>
        <UploadOneImage :image="{ src: itemCharacter.other.imageSrc }" @callback="getImageUpLoad" />
        <div class="mb-3 text-center mt-2 d-flex justify-center">
          Code :
          <h4>{{ itemCharacter.other.code }}</h4>
        </div>
      </v-col>
    </template>
  </v-container>
</template>

<script>
import AvatarGrid from './AvatarGrid';

export default {
  components: { AvatarGrid },
  props: {
    itemCharacter: {
      type: Object,
      default: () => {},
    },
    itemDefault: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    blayout() {
      return this.$store.state.bookeditor.blayout;
    },
  },
  data() {
    return {
      isShowAvatarGrid: this.itemCharacter?._id ? true : false,
      characters: [],
      ratios: [
        { label: 'Fit', value: 'none' },
        { label: 'Preserve', value: 'XMidYMid meet' },
      ],
    };
  },
  watch: {
    itemCharacter: {
      handler: function() {
        console.log(`true`);
      },
      deep: true,
    },
  },
  methods: {
    changePosition() {
      this.$emit('loadLayout');
    },
    getImageUpLoad(image) {
      this.$emit('setDataOther', { code: image.alt_text.split('.')[0], imageSrc: image.src, image: image._id });
    },
    getsetDataImage(data) {
      this.$emit('setDataImage', data);
    },
    changeCharacter(item) {
      this.isShowAvatarGrid = false;
      let findItem = this.$store.state.bookeditor.characters.find(it => it.code === item);
      if (findItem) {
        // this.itemCharacter.characterResources = null;
        this.$emit('setData', Object.assign({}, findItem));
        this.isShowAvatarGrid = true;
        this.$forceUpdate();
      } else {
        this.$emit('setDataOther', { code: '', imageSrc: '', image: '' });
        this.isShowAvatarGrid = true;
      }
    },
  },
  mounted() {
    this.characters = this.$store.state.bookeditor.characters.map(it => it);
    this.characters.push({
      code: 'other',
      title: 'Other',
    });
    if (this.itemCharacter?._id) {
      let findIndex = this.characters.findIndex(
        it => it.code === this.itemDefault?.targetCharacter?.code && it.code !== 'other',
      );
      console.log('findIndex', findIndex);
      if (findIndex > -1) {
        this.characters[findIndex].propertyAvatar = this.itemDefault?.characterResources;
      }
    }
    console.log(this.characters, 'characters');
  },
};
</script>

<style lang="scss" scoped></style>
