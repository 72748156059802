export default {
  props: ['onSaved'],
  data() {
    return {
      showed: false,
      model: {},
      errors: [],
    };
  },
  methods: {
    async show(model, type) {
      if (typeof model === 'string') {
        let m = await this.getModel(model);
        if (!m) return;
        this.model = m;
      } else {
        this.model = model || {};
      }
      this.showed = true;
    },
    async onSave() {
      return true;
    },
    async onSubmit(e) {
      e.preventDefault();
      if (this.$v.$invalid) {
        return;
      }
      let saved = await this.onSave();
      if (saved) this.showed = false;
      if (typeof this.onSaved === 'function') {
        this.onSaved();
      }
    },
  },
};
