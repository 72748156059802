<template>
  <v-app id="book2">
    <v-app-bar app flat height="50" style="right: 0px; left: 0px">
      <div class="d-flex pointer align-center" style="width: 100%">
        <div class="d-flex pointer align-center" v-if="pageLayout && bookDetail" @click="back">
          <i class="fas fa-arrow-left fz-20 pt-1 mr-2"></i>
          <h3>{{ pageLayout.code }} - {{ bookDetail.code }}</h3>
        </div>
        <v-spacer />
        <div>
          <v-btn @click="submit" color="primary"> Save </v-btn>
        </div>
      </div>
      <!-- <span v-if="itemDefault">
        {{ itemDefault.targetCharacter.code }}
      </span> -->
      <!-- <v-spacer></v-spacer> -->
    </v-app-bar>

    <v-navigation-drawer clipped left app>
      <div class="pa-3">
        <v-btn small color="primary" width="100%">
          <change-bg-page @loadingBg="isLoadingImage = true" @setBG="getBG" />
        </v-btn>
      </div>

      <div class="pa-3">
        <v-list dense>
          <v-subheader> <h3>Properties</h3></v-subheader>
          <div class="my-2">
            <v-menu bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on" color="primary" width="100%"> Add Properties </v-btn>
              </template>

              <v-list>
                <v-list-item @click="addObjectToPage('text')" class="pointer">
                  <v-list-item-title>Add text content</v-list-item-title>
                </v-list-item>

                <v-list-item @click="addObjectToPage('character')" class="pointer">
                  <v-list-item-title>Add image content</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-list-item-group v-model="selectedItem" color="primary" mandatory>
            <draggable v-model="objects" @change="change">
              <transition-group>
                <v-list-item v-for="(item, i) in objects" :key="i + `1`" @click="selectObject(item)">
                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center">
                      <i class="fas fa-bars mr-2"></i>
                      {{ item.objectType }} - {{ item.id }}
                      <v-spacer />
                      <v-btn @click.stop="deleteItem(i)" icon><i class="fas fa-trash"></i></v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </transition-group>
            </draggable>
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-navigation-drawer app clipped right v-if="sideRight" width="380">
      <config-character
        @loadLayout="loadLayout"
        @setDataOther="setDataOther"
        :itemDefault="itemDefault"
        @setDataImage="getDataImage"
        v-if="type === 'character'"
        :itemCharacter="objects[selectedItem]"
        @setData="getDataCharacter"
      />
      <config-content @loadLayout="loadLayout" v-if="type === 'text'" :itemCharacter="objects[selectedItem]" />
    </v-navigation-drawer>

    <v-main style="background-color: #dddd" class="d-flex justify-center">
      <div id="show-preview" style="width: 100%"></div>
      <!-- <div v-else style="width:100%">...Loading</div> -->
    </v-main>
    <v-overlay :value="overlay">
      <div class="fz-18">
        Loading .....
        <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
  </v-app>
</template>

<script>
import imageHelpers from '@/helpers/image';
import draggable from 'vuedraggable';

import { bookApi } from '@/apis/book';
import ConfigCharacter from './ConfigCharacter.vue';
import ConfigContent from './ConfigContent.vue';
import ChangeBgPage from './ChangeBgPage.vue';
import event from '@/plugins/event-bus';

export default {
  components: { draggable, ConfigCharacter, ConfigContent, ChangeBgPage },
  data() {
    return {
      overlay: false,
      pageLayout: {},
      imageHelpers,
      isLoadingImage: true,
      sideRight: true,
      type: null,
      objects: [],
      selectedItem: 0,
      itemDefault: null,
      bookDetail: null,
      xmlContent: '',
      executor: null,
    };
  },
  // components: { BookEditor },
  computed: {
    blayout() {
      return this.$store.state.bookeditor.blayout;
    },
    characters() {
      return this.$store.state.bookeditor.characters;
    },
  },
  methods: {
    change() {},
    async getBG(image) {
      if (image) {
        let { bookId, layoutId } = this.$route.params;

        let res = await bookApi.updateLayout(bookId, layoutId, {
          ...this.pageLayout,
          backgroundSrc: image.src,
        });
        this.loadData();
        this.isLoadingImage = false;
      }
    },
    deleteItem(k) {
      this.objects.splice(k, 1);
    },
    getDataImage(data) {
      this.objects[this.selectedItem].characterResources[data.characterResources].data[data.indexAvatar] = data.data;
    },
    setDataOther(item) {
      this.objects[this.selectedItem].other = Object.assign({}, item);
      this.objects[this.selectedItem].other.imageSrc = item.imageSrc;
      this.objects[this.selectedItem].characterResources = [];
      this.$forceUpdate();
    },
    getDataCharacter(item) {
      this.objects[this.selectedItem].characterResources = item.propertyAvatar.map(p => {
        return {
          ...p,
          data: p.data.map(d => {
            return {
              ...d,
              dataValue: '',
            };
          }),
        };
      });
      if (item.code === this.itemDefault.targetCharacter.code && this.itemCharacter._id) {
        this.objects[this.selectedItem].characterResources = [...this.itemDefault.characterResources];
      }
      this.itemCharacter.characterResources = this.objects[this.selectedItem].characterResources.map(it => it);
    },
    async submit() {
      let { bookId, layoutId } = this.$route.params;

      let res = await bookApi.updateLayout(bookId, layoutId, {
        ...this.pageLayout,
        objects: this.objects.map((it, index) => {
          return {
            ...it,
            ordinal: index + 1,
          };
        }),
      });
      this.pageLayout = res.data.pageLayout;
      this.loadData();
    },
    selectObject(item) {
      this.type = item.objectType === 'image' ? `character` : 'text';
      this.sideRight = true;
      this.itemCharacter = JSON.parse(JSON.stringify(item));
      this.itemDefault = JSON.parse(JSON.stringify(item));
    },
    addObjectToPage(type) {
      this.type = type;
      if (type === `character`) {
        let obj = {
          id: this.objects.length + 1,
          objectType: 'image',
          targetCharacter: {
            code: '',
          },
          width: '2400',
          height: '2400',
          x: '0',
          y: '0',
          characterResources: [],
        };
        this.objects.push(obj);
      }
      if (type === `text`) {
        let obj = {
          id: this.objects.length + 1,
          objectType: 'text',
          textTemplate: '',
          font: {
            fontFamily: '',
            fontStyle: 'normal',
            fontWeight: 0,
            src: '',
            format: '',
            id: null,
          },
          style: '',
          x: '0',
          y: '0',
          width: '0',
          height: '0',
          fontSize: 24,
          textAlign: 'center',
          textColor: ``,
          textData: [],
          ordinal: this.objects.length + 1,
        };
        this.objects.push(obj);
      }
      this.selectObject(this.objects[this.objects.length - 1]);
      this.selectedItem = this.objects.length - 1;
    },
    back() {
      let { bookId, layoutId } = this.$route.params;
      window.location.href = `/admin/books/${bookId}`;
    },
    onClick() {},
    async loadLayout() {
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }
      this.executor = setTimeout(async () => {
        await this.loadPreview({ ...this.pageLayout, objects: this.objects });
      }, 100);
    },
    async loadPreview(data) {
      let { bookId, layoutId } = this.$route.params;
      let url = window.location.href.includes(`localhost`) ? `https://book.lattex.xyz/` : '';
      //preview
      fetch(`${url}/api/books/${bookId}/page-layouts/preview`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem(`access-token`)}`,
          'x-store-id': `${localStorage.getItem(`store-id`)}`,
          storeId: `${localStorage.getItem(`store-id`)}`,
          'x-access-token': `${localStorage.getItem(`access-token`)}`,
        },
        body: JSON.stringify({
          pageLayout: {
            ...data,
          },
          currentObjectId: this.objects[this.selectedItem]?.id || '',
        }),
      })
        .then(response => response.text())
        .then(data => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(data, 'text/xml');
          xml.querySelector('svg').setAttribute('width', '100%');
          xml.querySelector('svg').setAttribute('height', '100%');
          document.querySelector('#show-preview').innerHTML = xml.querySelector('svg').outerHTML;
        });
    },
    async loadData() {
      let { bookId, layoutId } = this.$route.params;
      try {
        let res = await bookApi.getLayout(bookId, layoutId);
        this.pageLayout = res.data.pageLayout;
        this.objects = this.pageLayout.objects;

        if (!this.pageLayout) return;
        res = await bookApi.getBook(bookId);
        let book = res.data.book;
        this.bookDetail = res.data.book;
        let url = window.location.href.includes(`localhost`) ? `https://book.lattex.xyz/` : '';
        //preview
        this.loadPreview(this.pageLayout);
        res = await bookApi.getPage(bookId, this.pageLayout.page);
        let page = res.data.page;
        res = await bookApi.getCharacters(bookId);
        let characters = res.data.characters;
        this.$store.commit('bookeditor/open', {
          book: book,
          page: page,
          pageLayout: this.pageLayout,
          characters: characters,
          // blayout: DataParser.createLayout(pageLayout, page, book),
        });
      } catch (err) {
        return;
      }
      this.isLoadingImage = false;
    },
  },
  async created() {
    await this.loadData();
    if (this.objects.length > 0) {
      this.selectObject(this.objects[0]);
    }
    let vm = this;
    event.$on('loadImageBook', () => {
      vm.overlay = true;
    });
    event.$on('doneImageBook', () => {
      vm.overlay = false;
    });
  },
  mounted() {
    // var svgholder = document.getElementById('show-preview');
    // $(window).load(function() {
    //   var svg = document.getElementById('show-preview').getSVGDocument();
    //   console.log('svg', svg);
    // });
    // svgholder.onload = function() {
    //   alert('some svg loaded');
    // };
  },
};
</script>

<style lang="scss">
#book2 {
  .v-main__wrap {
    display: flex;
    justify-content: center;
  }
}
.bg-page {
  width: 800px;
  height: 800px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0;
}
</style>
