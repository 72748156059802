<template>
  <v-container v-if="itemCharacter" fluid class="form-component">
    <v-row class="mt-2">
      <v-col cols="12">
        <editor-book :dataT.sync="itemCharacter.textTemplate" :title="`Text Template`" />
        <!-- <label for="">Text Template</label>
        <v-textarea type="number" v-model="itemCharacter.textTemplate" /> -->
      </v-col>
      <v-col cols="3">
        <label for="">Width</label>
        <v-text-field type="number" v-model="itemCharacter.width" @input="changePosition" />
      </v-col>
      <v-col cols="3">
        <label for="">Height</label>

        <v-text-field type="number" v-model="itemCharacter.height" @input="changePosition" />
      </v-col>
      <v-col cols="3">
        <label for="">X</label>

        <v-text-field type="number" v-model="itemCharacter.x" @input="changePosition" />
      </v-col>
      <v-col cols="3">
        <label for="">Y</label>

        <v-text-field type="number" v-model="itemCharacter.y" @input="changePosition" />
      </v-col>
      <v-col cols="12">
        <v-divider class=""></v-divider>
      </v-col>
      <v-col cols="12">
        <label for="" class="mx-3">Upload Font</label>
        <add-font @setFont="getFont" />
        <span>
          {{ itemCharacter.font.fontFamily }}
        </span>
        <i v-if="itemCharacter.font.src" class="fas fa-check color-main"></i>
      </v-col>
      <v-col cols="6">
        <label for="">Font Family</label>

        <v-text-field hide-details="" v-model="itemCharacter.font.fontFamily" />
      </v-col>
      <v-col cols="6">
        <label for="">Font Style</label>
        <v-select
          hide-details=""
          v-model="itemCharacter.font.fontStyle"
          :items="[`normal`, `italic`, `oblique`, `initial`]"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <label for="">Font Weight </label>
        <v-text-field hide-details="" type="number" v-model.number="itemCharacter.font.fontWeight" />
      </v-col>
      <v-col cols="6">
        <label for="">Font Size</label>
        <v-text-field hide-details="" @input="addStyle" type="number" v-model.number="itemCharacter.fontSize" />
      </v-col>
      <v-col cols="6">
        <label for="">Text Align</label>

        <v-btn-toggle @input="addStyle" v-model="itemCharacter.textAlign" color="primary" dense group mandatory>
          <v-btn :value="'left'" text>
            <v-icon>mdi-format-align-left</v-icon>
          </v-btn>

          <v-btn :value="'center'" text>
            <v-icon>mdi-format-align-center</v-icon>
          </v-btn>

          <v-btn :value="'right'" text>
            <v-icon>mdi-format-align-right</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="6" class="my-1">
        <label for="">Text Color</label>
        <div class="position:relative">
          <v-text-field
            @input="addStyle"
            placeholder="Color code start with #"
            hide-details=""
            v-model="itemCharacter.color"
          />
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider class=""></v-divider>
      </v-col>

      <v-col>
        <div class="d-flex flex-end">
          <v-spacer />
          <v-btn @click="addTextKey" small color="primary">Add Key</v-btn>
        </div>
        <div class="pa-2 d-flex align-center" v-for="(textKey, k) in itemCharacter.textData" :key="k + textKey.key">
          <div>Key : {{ textKey.key }}</div>
          <v-spacer />
          <div>
            <v-btn @click="editItem(textKey, k)" icon><i class="fas fa-edit"></i></v-btn>
            <v-btn @click="deleteItem(k)" icon><i class="fas fa-trash"></i></v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <add-key-modal ref="modal" :textKey="textKeyItem" :onSaved="loadData" />
  </v-container>
</template>

<script>
import AddFont from './AddFont.vue';
import AddKeyModal from './AddKeyModal.vue';
import EditorBook from './EditorBook.vue';
export default {
  components: { AddKeyModal, AddFont, EditorBook },
  props: {
    itemCharacter: {
      type: Object,
      default: () => {},
    },
    itemDefault: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    blayout() {
      return this.$store.state.bookeditor.blayout;
    },
  },
  data() {
    return {
      isShowSelectionColor: false,
      textKeyItem: null,
      isShowAvatarGrid: this.itemCharacter?._id ? true : false,
      characters: [],
      ratios: [
        { label: 'Fit', value: 'none' },
        { label: 'Preserve', value: 'XMidYMid meet' },
      ],
    };
  },
  watch: {
    'itemCharacter.textTemplate': function(params) {
      this.changePosition();
    },
    'itemCharacter.textAlign': function(params) {
      this.addStyle();
    },
    itemCharacter: {
      handler: function() {
        console.log(`true`);
      },
      deep: true,
    },
  },
  methods: {
    changePosition() {
      this.$emit('loadLayout');
    },
    addStyle() {
      this.itemCharacter.style = `font-size :${this.itemCharacter.fontSize}px; text-align: ${this.itemCharacter.textAlign}; font-family: ${this.itemCharacter.font.fontFamily}; color :  ${this.itemCharacter.color}`;
      console.log(' this.itemCharacter', this.itemCharacter);
    },
    getFont(font) {
      this.itemCharacter.font.src = font.src;
      this.itemCharacter.font.fontFamily = font.alt_text.split('.')[0];
      console.log('data', font);
    },
    deleteItem(k) {
      this.itemCharacter.textData.splice(k, 1);
    },
    addTextKey() {
      console.log(' this.itemCharacter', this.itemCharacter);
      this.itemCharacter.textData.push({
        key: '',
        character: {
          code: '',
        },
        appendType: 'none',
        appendText: null,
        maxLength: null,
        pickAt: null,
      });
      this.textKeyItem = this.itemCharacter.textData[this.itemCharacter.textData.length - 1];
      this.$refs.modal.show();
    },
    loadData() {},
    editItem(item, id) {
      this.$refs.modal.show(id);
      this.textKeyItem = item;
    },
    getImageUpLoad(image) {
      this.$emit('setDataOther', { code: image.alt_text.split('.')[0], imageSrc: image.src, image: image._id });
    },
    getsetDataImage(data) {
      this.$emit('setDataImage', data);
    },
    changeCharacter(item) {
      this.isShowAvatarGrid = false;
      let findItem = this.$store.state.bookeditor.characters.find(it => it.code === item);
      if (findItem) {
        // this.itemCharacter.characterResources = null;
        this.$emit('setData', Object.assign({}, findItem));
        this.isShowAvatarGrid = true;
        this.$forceUpdate();
      } else {
        this.$emit('setDataOther', { code: '', imageSrc: '', image: '' });
        this.isShowAvatarGrid = true;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
