<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <label class="mb-1 fz-14" style="font-weight:600">
      {{ title }}
    </label>
    <editor
      api-key="cp1okc1v5mgid311kk2qxto3hn01wllmxcduuffi34b50rwr"
      v-model="html"
      @input="$emit('update')"
      :init="{
        toolbar_mode: 'wrap',
        toolbar_sticky: true,

        selector: 'textarea',
        menubar: false,
        plugins: '',

        toolbar: '',
        fontsize_formats: '8px 9px 10px 11px 12px 14px 16px 18px 24px 30px 36px 48px 60px 72px 96px',
        max_height: height,
        min_height: height,
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        /* without images_upload_url set, Upload tab won't show up*/
        /* we override default upload handler to simulate successful upload*/
        browser_spellcheck: true,
        images_upload_handler: example_image_upload_handler,
      }"
      @onSelectionChange="handlerFunction"
    />
  </div>
</template>

<script>
import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
import imageHelpers from '@/helpers/image';
import Editor from '@tinymce/tinymce-vue';
function example_image_upload_handler() {
  console.log('abd');
}
export default {
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    dataT: {
      type: String,
      default: '',
    },
    model: {
      type: String,
      default: 'bodyHtml',
    },
    height: {
      type: Number,
      default: null,
    },
    minHeight: {
      type: Number,
      default: 100,
    },
  },
  components: {
    editor: Editor,
  },
  watch: {
    html() {
      this.$emit('update:dataT', this.html);
    },
  },
  data() {
    return {
      data: '',
      html: '',
      imageHelpers,
    };
  },
  mounted() {
    this.html = this.dataT;
  },
  methods: {
    handlerFunction(html) {},
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },
    hex(value) {
      return Math.floor(value).toString(16);
    },
    async onChangeImage(e) {
      console.log(e, 'e');
      let size = { width: 0, height: 0 };
      let inputTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
      var _URL = window.URL || window.webkitURL;

      let img = new Image();
      var objectUrl = _URL.createObjectURL(e);
      img.onload = function() {
        size.width = this.width;
        size.height = this.height;
      };
      img.src = _URL.createObjectURL(e);
      if (inputTypes.includes(e.type)) {
        let file = e;
        try {
          let type = file.name.split('.')[file.name.split('.').length - 1];
          let id = this.objectId();
          let urlData = await uploadService.get({ filename: `lattehub-image-${id}.${type}` });
          if (urlData) {
            let res = await axios.put(urlData.data.presignedUrl, file, {
              headers: {
                'Content-Type': `${file.type}`,
              },
            });
            let obj = {
              alt: file.name,
              alt_text: file.name,
              src: urlData.data.imagePath,
              _id: id,
              size: size,
            };
            return obj;
            // this.$emit('callback', obj, this.itemSetData);
          }
        } catch (error) {
          console.log('error', error);
          this.$store.commit('setMessages', {
            messages: 'Error image ',
            type: 'error',
          });
        }
      } else {
        alert('Type not support');
      }
    },
    async example_image_upload_handler(blobInfo, success, failure, progress) {
      let urlImage = await this.onChangeImage(blobInfo.blob());
      if (urlImage) success(this.imageHelpers.url(urlImage.src, { width: 1000, height: 1000 }));
    },
  },
  created() {
    // console.log('1212');
  },
};
</script>
<style lang="scss">
.tox-button--naked {
  .tox-icon.tox-tbtn__icon-wrap {
    display: none;
  }
}
.tox-editor-header {
  top: 60px !important;
}
.tox-statusbar__branding {
  display: none !important;
}
</style>
