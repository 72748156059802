<template>
  <label title="Add font" class="myFile2 mb-3 mb-3 d-inline-block pointer">
    <i class="fas fa-plus-circle fz-20 mt-3 pointer"></i>
    <input ref="fileUploader" style="width: 30px" @change="uploadFont" type="file" class="pointer" accept=".ttf,.otf" />
  </label>
</template>

<script>
import { uploadService } from '@/apis/upload.s';
import fileHelpers from '@/helpers/file';
import { personalizesApi } from '@/apis/personalizes';

import axios from 'axios';
export default {
  methods: {
    async removeFont(item) {
      try {
        console.log('item', item);
        await personalizesApi.removeFont(item.id);
        this.$emit('loadFont');
      } catch (error) {}
      console.log(item);
    },
    changeFont(e) {
      if (e && typeof e === 'object') {
        let f = this.fonts.find(item => item.name === e.name);
        this.defaultData.fontFamily = f.name;
        this.$emit('updateData', this.defaultData, this.type);
      }
    },
    clickUploadFile() {
      this.resetImageUploader();
      this.$refs.fileUploader.click();
    },
    resetImageUploader() {
      this.$refs.fileUploader.value = '';
    },
    deleteFile(key) {
      this.$emit('deleteCallback', key);
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },

    hex(value) {
      return Math.floor(value).toString(16);
    },
    async uploadFont(e) {
      console.log(e.target.files[0]);
      let inputTypes = ['otf', 'tff'];
      let file = e.target.files[0];
      let type = file.name.split('.')[file.name.split('.').length - 1];
      let id = this.objectId();
      try {
        let urlData = await uploadService.get({ filename: `${file.name.replace(/\s/g, '-')}` });
        if (urlData) {
          let res = await axios.put(urlData.data.presignedUrl, file, {
            headers: {
              'Content-Type': `${file.type}`,
            },
          });
          let obj = {
            alt: file.name,
            alt_text: file.name,
            src: urlData.data.imagePath,
            _id: id,
            // fullPath: this.imageHelpers.url(urlData.data.imagePath),
          };
          this.$emit('setFont', obj);
        }
      } catch (error) {
        console.log('error', error);
        this.$store.commit('setMessages', {
          messages: 'Error  ',
          type: 'error',
        });
      }
      this.$refs.fileUploader.value = '';
    },
    addText() {
      this.$emit('addText');
    },
    refresh() {
      location.reload();
    },
    changeFontStyle() {
      // console.log('sdsd');
    },
    onClickOutside() {
      this.isShowSelectionColor = false;
    },
    updateValue() {
      //   this.isShowSelectionColor = false;
      this.defaultData.fontColor = this.colors.hex;
    },
  },
};
</script>

<style lang="scss">
.myFile {
  position: relative;
  overflow: hidden;
  float: left;
  clear: left;
}

.myFile input[type='file'] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  font-size: 30px;
  filter: alpha(opacity=0);
}

.myFile2 input[type='file'] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  font-size: 30px;
  filter: alpha(opacity=0);
}
.myFile2 {
  .btn-upload {
    background: #1f739e;
    font-weight: bold;
    color: white;
    padding: 8px 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }
}
</style>
