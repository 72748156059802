<template>
  <v-dialog persistent v-model="showed" width="720">
    <v-card relative>
      <v-card-title class="">
        Update Text Key
      </v-card-title>
      <div class="close-icon" @click="showed = false">
        <v-icon>fas fa-times</v-icon>
      </div>
      <v-divider class=""></v-divider>
      <v-card-text v-if="textKey">
        <v-container class="form-component">
          <v-form>
            <v-row>
              <v-col cols="12">
                <label for="">Title</label>
                <v-text-field hide-details="" type="text" name="title" label="" v-model="textKey.key" />
              </v-col>
              <v-col cols="12">
                <label for="">Characters</label>
                <v-select
                  v-model="textKey.character.code"
                  item-value="code"
                  item-text="title"
                  :items="this.$store.state.bookeditor.characters"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider class=""></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" @click="showed = false"> Cancel </v-btn>
        <v-btn :loading="isLoadingButton" color="primary" @click="onSubmit">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bookApi } from '@/apis/book';
import DataModal from '@/mixins/data-modal';
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators';

export default {
  props: ['textKey'],
  data() {
    return {
      isLoadingButton: false,
      showed: false,
    };
  },
  validations: {
    model: {
      title: {
        required,
      },
    },
  },
  methods: {
    show() {
      this.showed = true;
    },
    async getModel(id) {
      try {
        let res = await bookApi.getGroup(this.book, id);
        return res.data.characterGroup;
      } catch (err) {
        return null;
      }
    },
    async onSubmit() {
      this.showed = false;
    },
  },
};
</script>
